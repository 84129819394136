import React from "react";
import FooterLinks from "./FooterLinks";
import { UseStateValue } from "../../../state";

const PLAYO_LOGO_FOOTER = "https://playo-website.gumlet.io/playo-website-v3/playo_footer_logo.png";

const COMPANY_URLS = {
   title: "Company",
   data: [
      {
         title: "ABOUT US",
         url: "/about"
      },
      {
         title: "LEARN",
         url: "https://blog.playo.co"
      },
      {
         title: "CONTACT",
         url: "/contact"
      },
      {
         title: "CAREERS",
         url: "/about/#career"
      },
      {
         title: "PARTNER WITH US",
         url: "/partner-with-us"
      }
   ]
};

const SOCIAL_URLS = {
   title: "Social",
   data: [
      {
         title: "INSTAGRAM",
         url: "https://www.instagram.com/playoapp/"
      },
      {
         title: "FACEBOOK",
         url: "https://www.facebook.com/PlayoApp"
      },
      {
         title: "LINKEDIN",
         url: "https://www.linkedin.com/company/playo"
      },
      {
         title: "TWITTER",
         url: "https://twitter.com/playoApp"
      }
   ]
};

const PRIVACY_TERMS = {
   title: "Privacy & Terms",
   data: [
      {
         title: "FAQS",
         url: "https://playo.zendesk.com/hc/en-us"
      },
      {
         title: "PRIVACY POLICY",
         url: "/privacy"
      },
      {
         title: "TERMS OF SERVICE",
         url: "/terms"
      },
      {
         title: "CANCELLATION POLICY",
         url: "/cancellation-refund"
      }
   ]
};

const Footer = () => {
   const [
      { modal, activityModals, activityDetail: activityDetails, activityData, users, joinStatus },
      dispatch
   ] = UseStateValue();
   return (
      <section
         className={`flex justify-between w-full text-mute_text flex-wrap ${
            activityDetails?.activityAction ? " pb-32" : "pb-0"
         }`}
      >
         <div className=" mb-14">
            <img src={PLAYO_LOGO_FOOTER} height={100} width={100} alt="PlayO" />
            <div className="py-4 leading-6">
               <p>© 2024 Techmash Solutions Pvt. Ltd.</p>
               <p>All Rights Reserved.</p>
            </div>
         </div>
         <div className="flex gap-24 flex-wrap">
            {[COMPANY_URLS, SOCIAL_URLS, PRIVACY_TERMS].map((items, idx) => (
               <FooterLinks linkData={items} key={idx} />
            ))}
         </div>
      </section>
   );
};

export default Footer;
